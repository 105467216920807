var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"main"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"title"},[_vm._v("Mega Purple Gameplay")]),_c('br'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"more-button",on:{"click":function($event){return _vm.openLink('https://docs.purpleball.io/')}}},[_vm._v(" Learn more ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon1.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("The chance to become a millionaire")])]),_c('div',{staticClass:"desc"},[_vm._v(" Mega Purple is a voluntary purchase, and all purchasers are considered to agree to and abide by these ."),_c('br'),_c('br'),_vm._v(" Mega Purple betting is divided into a red ball number area and a purple ball number area, each containing numbers from 1 to 26. In the hash result, numbers 1-9 directly correspond to the winning numbers, and letters a-z correspond to numbers 1-26 (case insensitive). Due to the uncertainty of hashing, the winning numbers may repeat, and the probability of winning with numbers 1-9 is higher. For each bet, choose 3 red ball numbers and 1 purple ball number, with a single bet amount of 0.01 SOL."),_c('br'),_c('br'),_vm._v(" Purchasers can choose machine-selected numbers or self-selected numbers. Machine-selected numbers are randomly generated by the betting machine, while self-selected numbers are input into the sales terminal by the purchaser."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon2.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("Prize Setting")])]),_c('div',{staticClass:"desc"},[_vm._v(" Mega Purple prize levels are divided into high prize levels and low prize levels. The first and second prizes are high prize levels, while the third and fourth prizes are low prize levels. The current period's prize pool, minus the low prize level prize, forms the high prize level prize pool. The prize levels and distribution are as follows:"),_c('br'),_c('br'),_vm._v(" First Prize: Guessing all three red balls + one purple ball correctly will split 50% of the total jackpot "),_c('br'),_c('br'),_vm._v(" Second Prize: Guessing 3 red balls correctly or 2 red balls and 1 purple ball correctly will split 3% of the total jackpot"),_c('br'),_c('br'),_vm._v(" Third Prize: Guessing 2 red balls correctly or 1 red ball and 1 purple ball correctly will win a fixed prize of 0.1 SOL"),_c('br'),_c('br'),_vm._v(" Fourth Prize: Guessing only the purple ball correctly wins a fixed prize of 0.03 SOL"),_c('br'),_c('br'),_vm._v(" 2% of the total betting amount for each period will be used for the development of Mega Purple"),_c('br'),_c('br'),_vm._v(" In Mega Purple, if there is no high prize winner in the current period, the prize pool will continue to accumulate"),_c('br'),_c('br'),_vm._v(" If the high prize pool exceeds (including) 100 SOL, and if it falls below 100 SOL, the remaining part will be subsidized by the Mega Purple development and construction fund. If there is any surplus in the prize pool for each period, it will be used to replenish the fund first"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon3.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("How it works")])]),_c('div',{staticClass:"desc"},[_vm._v(" Mega Purple draws daily between 12:00-12:30 UTC. The MegaPL drawing wallet will make a transfer to a random wallet. Due to the uncertainty of hashing, this fair drawing method ensures fairness for every player. We select the last four digits of one transfer each day."),_c('br'),_c('br'),_vm._v(" Choose 3 red balls and 1 purple ball as the betting numbers. Letters a-z correspond to numbers 1-26, and numbers 1-9 directly correspond to the winning numbers."),_c('br'),_c('br'),_vm._v(" For example: 4vsBp1mcUKVSa3QpbE3GCLtdU5JikHAAAWB5M4GvyJ4Y8dLp4UKPTyd9ZfxFN3rLsvNdhH1V56jwfiGSjuW3Nx4Q"),_c('br'),_c('br'),_vm._v(" The winning number is the last four digits: Nx4Q"),_c('br'),_c('br'),_vm._v(" Red Ball: 14, 24, 4"),_c('br'),_c('br'),_vm._v(" Purple Ball: 17"),_c('br'),_c('br'),_vm._v(" 12:00-12:30 (UTC) is the drawing time. All bets placed during this period will be included in the next period."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon4.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("Huge pump")])]),_c('div',{staticClass:"desc"},[_vm._v(" The winning qualification is determined according to the consistency of the single bet number selected by the purchaser with the winning number of the current period. The specific regulations are as follows:"),_c('br'),_c('br'),_vm._v(" First Prize: The betting number is identical to the winning number of the current period (in any order, purple ball included)"),_c('br'),_c('br'),_vm._v(" Second Prize: The betting number matches 3 red balls or 2 red balls and 1 purple ball of the current period"),_c('br'),_c('br'),_vm._v(" Third Prize: The betting number matches any 2 red balls or 1 red ball and 1 purple ball of the current period"),_c('br'),_c('br'),_vm._v(" Fourth Prize: The betting number matches the purple ball of the current period"),_c('br'),_c('br'),_vm._v(" There is only one winning chance for each bet number in the current period."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon5.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("Prize Redemption")])]),_c('div',{staticClass:"desc"},[_vm._v(" At 12:00-12:30 (UTC), Mega Purple will announce all players' betting information on Discord"),_c('br'),_c('br'),_vm._v(" Wow, if you win a big prize, don't worry. We will distribute the reward to your betting wallet within 30 minutes, so please check it."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desc-box"},[_c('div',{staticClass:"sub-title"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/images/desc-icon6.png"),"alt":""}}),_c('div',{staticClass:"text"},[_vm._v("Risk Warning")])]),_c('div',{staticClass:"desc"},[_vm._v(" Risk of fund loss: Due to the nature of the game, there is a possibility of losing all invested funds. Please ensure you are using disposable funds that will not significantly impact your financial situation."),_c('br'),_c('br'),_vm._v(" Technical risk: Mega Purple's drawing mechanism relies on blockchain technology and hash algorithms. While these technologies are generally considered secure, there may be risks of technical vulnerabilities or attacks."),_c('br'),_c('br'),_vm._v(" Market volatility risk: SOL, as the betting currency, may experience significant price fluctuations, which could affect your actual gains or losses."),_c('br'),_c('br'),_vm._v(" Rule change risk: The rules and prize distribution methods of Mega Purple may be adjusted at any time. Please continuously pay attention to official announcements for the latest information."),_c('br'),_c('br'),_vm._v(" Network risk: Your betting and prize redemption processes depend on internet connectivity. Network interruptions or delays may affect your gaming experience and fund security."),_c('br'),_c('br'),_vm._v(" Please fully understand the above risks before deciding whether to participate in the Mega Purple game."),_c('br')])])
}]

export { render, staticRenderFns }