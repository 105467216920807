<template>
  <section class="main">
    <div class="content">
      <div class="card-box">
        <div class="title">Mega Purple Gameplay</div>
        <br />
        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon1.png" alt="" class="icon" />
            <div class="text">The chance to become a millionaire</div>
          </div>
          <div class="desc">
            Mega Purple is a voluntary purchase, and all purchasers are
            considered to agree to and abide by these .<br /><br />
            Mega Purple betting is divided into a red ball number area and a
            purple ball number area, each containing numbers from 1 to 26. In
            the hash result, numbers 1-9 directly correspond to the winning
            numbers, and letters a-z correspond to numbers 1-26 (case
            insensitive). Due to the uncertainty of hashing, the winning numbers
            may repeat, and the probability of winning with numbers 1-9 is
            higher. For each bet, choose 3 red ball numbers and 1 purple ball
            number, with a single bet amount of 0.01 SOL.<br /><br />
            Purchasers can choose machine-selected numbers or self-selected
            numbers. Machine-selected numbers are randomly generated by the
            betting machine, while self-selected numbers are input into the
            sales terminal by the purchaser.<br />
          </div>
        </div>
        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon2.png" alt="" class="icon" />
            <div class="text">Prize Setting</div>
          </div>
          <div class="desc">
            Mega Purple prize levels are divided into high prize levels and low
            prize levels. The first and second prizes are high prize levels,
            while the third and fourth prizes are low prize levels. The current
            period's prize pool, minus the low prize level prize, forms the high
            prize level prize pool. The prize levels and distribution are as
            follows:<br /><br />
            First Prize: Guessing all three red balls + one purple ball
            correctly will split 50% of the total jackpot <br /><br />
            Second Prize: Guessing 3 red balls correctly or 2 red balls and 1
            purple ball correctly will split 3% of the total jackpot<br /><br />
            Third Prize: Guessing 2 red balls correctly or 1 red ball and 1
            purple ball correctly will win a fixed prize of 0.1 SOL<br /><br />
            Fourth Prize: Guessing only the purple ball correctly wins a fixed
            prize of 0.03 SOL<br /><br />
            2% of the total betting amount for each period will be used for the
            development of Mega Purple<br /><br />
            In Mega Purple, if there is no high prize winner in the current
            period, the prize pool will continue to accumulate<br /><br />
            If the high prize pool exceeds (including) 100 SOL, and if it falls
            below 100 SOL, the remaining part will be subsidized by the Mega
            Purple development and construction fund. If there is any surplus in
            the prize pool for each period, it will be used to replenish the
            fund first<br />
          </div>
        </div>
        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon3.png" alt="" class="icon" />
            <div class="text">How it works</div>
          </div>
          <div class="desc">
            Mega Purple draws daily between 12:00-12:30 UTC. The MegaPL drawing
            wallet will make a transfer to a random wallet. Due to the
            uncertainty of hashing, this fair drawing method ensures fairness
            for every player. We select the last four digits of one transfer
            each day.<br /><br />
            Choose 3 red balls and 1 purple ball as the betting numbers. Letters
            a-z correspond to numbers 1-26, and numbers 1-9 directly correspond
            to the winning numbers.<br /><br />
            For example:
            4vsBp1mcUKVSa3QpbE3GCLtdU5JikHAAAWB5M4GvyJ4Y8dLp4UKPTyd9ZfxFN3rLsvNdhH1V56jwfiGSjuW3Nx4Q<br /><br />
            The winning number is the last four digits: Nx4Q<br /><br />
            Red Ball: 14, 24, 4<br /><br />
            Purple Ball: 17<br /><br />
            12:00-12:30 (UTC) is the drawing time. All bets placed during this
            period will be included in the next period.<br />
          </div>
        </div>
        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon4.png" alt="" class="icon" />
            <div class="text">Huge pump</div>
          </div>
          <div class="desc">
            The winning qualification is determined according to the consistency
            of the single bet number selected by the purchaser with the winning
            number of the current period. The specific regulations are as
            follows:<br /><br />
            First Prize: The betting number is identical to the winning number
            of the current period (in any order, purple ball included)<br /><br />
            Second Prize: The betting number matches 3 red balls or 2 red balls
            and 1 purple ball of the current period<br /><br />
            Third Prize: The betting number matches any 2 red balls or 1 red
            ball and 1 purple ball of the current period<br /><br />
            Fourth Prize: The betting number matches the purple ball of the
            current period<br /><br />
            There is only one winning chance for each bet number in the current
            period.<br />
          </div>
        </div>
        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon5.png" alt="" class="icon" />
            <div class="text">Prize Redemption</div>
          </div>
          <div class="desc">
            At 12:00-12:30 (UTC), Mega Purple will announce all players' betting
            information on Discord<br /><br />
            Wow, if you win a big prize, don't worry. We will distribute the
            reward to your betting wallet within 30 minutes, so please check
            it.<br />
          </div>
        </div>

        <div class="desc-box">
          <div class="sub-title">
            <img src="../assets/images/desc-icon6.png" alt="" class="icon" />
            <div class="text">Risk Warning</div>
          </div>
          <div class="desc">
            Risk of fund loss: Due to the nature of the game, there is a
            possibility of losing all invested funds. Please ensure you are
            using disposable funds that will not significantly impact your
            financial situation.<br /><br />
            Technical risk: Mega Purple's drawing mechanism relies on blockchain
            technology and hash algorithms. While these technologies are
            generally considered secure, there may be risks of technical
            vulnerabilities or attacks.<br /><br />
            Market volatility risk: SOL, as the betting currency, may experience
            significant price fluctuations, which could affect your actual gains
            or losses.<br /><br />
            Rule change risk: The rules and prize distribution methods of Mega
            Purple may be adjusted at any time. Please continuously pay
            attention to official announcements for the latest information.<br /><br />
            Network risk: Your betting and prize redemption processes depend on
            internet connectivity. Network interruptions or delays may affect
            your gaming experience and fund security.<br /><br />
            Please fully understand the above risks before deciding whether to
            participate in the Mega Purple game.<br />
          </div>
        </div>
        <div
          class="more-button"
          @click="openLink('https://docs.purpleball.io/')"
        >
          Learn more
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    openLink(url) {
      window.open(url);
    },
    openInvite() {
      this.showInvite = true;
    },
    changeUserInfo(payload) {
      this.userInfo = payload;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  padding-bottom: 100px;
}
.card-box {
  margin: 0.2rem 0.2rem 0;
  background: #101729;
  border-radius: 0.3rem;
  padding: 0.3rem;
  box-sizing: border-box;
  text-align: left;

  .desc-box {
    margin-bottom: 0.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 16px;
  }

  .sub-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;

    .icon {
      width: 24px;
      margin-right: 10px;
    }

    .text {
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      word-break: break-all;
    }
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    color: #8499cd;
    line-height: 20px;
    word-break: break-all;
  }
  .more-button {
    width: 88px;
    height: 36px;
    background: #081024;
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #1e2c4f;
    font-size: 14px;
    color: #5ffe99;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>