<template>
  <section class="main">
    <div class="content">
      <div class="card-box">
        <h3 class="title">Coming soon</h3>
      </div>
    </div>
  </section>
</template>
  <script>
export default {};
</script>
  <style lang="less" scoped>
.content {
  padding: 0.3rem 0 0;

  .card-box {
    width: 7.2rem;
    margin: 0 auto;
    background: #101729;
    border-radius: 0.4rem;
    padding: 0.4rem;
    box-sizing: border-box;

    .title {
      font-size: 0.4rem;
      color: #fff;
    }
  }
}
</style>